<template>
    <!-- 个人优秀作业详情 -->
    <div class="coursePersonnel-list">
        <div class="manage-wrapper">
            <div class="table-wrapper">
                <h2 class="table-title">
                    <img src="../../assets/images/main/bulltin-icon.png" alt="" class="title-icon">优秀作业详情</h2>
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column type="index" label="序号" style="background:#2373EC" align="center">
                        </el-table-column>
                        <el-table-column prop="themeName" label="作业名称" align="center"></el-table-column>
                        <el-table-column prop="createTime" label="上传时间" align="center"></el-table-column>
                    </el-table>
                    <!-- <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                        :limit.sync="pages.pageSize" @pagination="handlePageChange">
                    </pagination> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                // pages: { //分页
                //     currentPage: 1,
                //     pageSize: 10,
                //     total: 0,
                // },
                tableData: [],
            }
        },

        methods: {
            // handlePageChange(data) {
            //     this.pages.currentPage = data.pageIndex
            //     this.pages.pageSize = data.pageSize
            //     this.projectWorkRankingUser();
            // },
            async projectWorkRankingUser() {
                let params = {
                    // page: this.pages.currentPage,
                    // limit: this.pages.pageSize,
                    projectId: this.$store.state.user.currentProject.id,
                    userId: this.$route.params.id
                }
                let resData = await this.$Api.Project.projectWorkRankingUser(params);
                console.log(resData);
                this.tableData = resData.data
                // this.pages.total = resData.data.total
            },
        },
        mounted() {
            this.projectWorkRankingUser();
        }
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .coursePersonnel-list {

        .table-wrapper {
            border-radius: 8px;
            padding-top: 10px;
            background: #fff;
            padding: 0 26px 24px;
            box-shadow: 0px 2px 10px 0px #F5F8FA;
        }

        .table-title {
            line-height: 68px;
            font-size: 17px;
        }

        .export-btn {
            color: #595959;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 10px;
        }

        .export-icon {
            display: inline-block;
            background-image: url("~@/assets/images/admin/export.png");
            background-size: 100% 100%;
            width: 17px;
            height: 16px;
            margin-right: 8px;
            vertical-align: -2px;
        }

        .title-icon {
            width: 21px;
            height: 21px;
            margin-right: 9px;
            vertical-align: -4px;
        }

        .table {
            /deep/.el-table th {
                &>.cell {
                    white-space: pre-line;
                }
            }
        }

        @media screen and (min-width:1250px) {
            .table-wrapper {
                padding: 0 43px 24px;
            }
        }
    }
</style>